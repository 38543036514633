import Swiper from "swiper";
import '../Slider/assets/style.scss';


window.slider = function slider(container = 'swiper-references') {

    return new Swiper('.' + container, {
        slidesPerView:1.5,
        loop:true,
        spaceBetween:0,
        centeredSlides:true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        breakpoints: {
            991: {
                slidesPerView: 4
            },
            767: {
                slidesPerView: 2.5
            }
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
    });
};
window.sliderSteps = function sliderSteps(container = 'swiper-steps') {

    return new Swiper('.' + container, {
        slidesPerView:1,
        spaceBetween:0,
        noSwiping:false,
        touchRatio:1,
        pagination: {
            el: '.swiper-pagination',
        },
        breakpoints: {
            768: {
                slidesPerView: 3,
                noSwiping:true,
                touchRatio:0,
            }
        }
    });
};
